import ApiService from "@/core/services/api.service";

export default {

    // -----API DATA USER------
    getUserAll(credentials) {
        ApiService.setHeader();
        return ApiService.query("v2/verifikasi/master/user/all", { params: credentials })
    },
    getUserSingle(credentials) {
        ApiService.setHeader();
        return ApiService.query("v2/verifikasi/master/user/single", { params: credentials } )
    },
    verifUser(credentials) {
        ApiService.setHeader();
        return ApiService.put(`v2/verifikasi/master/user`, credentials)
    },

    // -----API DATA USER AKSES------
    getUserAkses(credentials) {
        ApiService.setHeader();
        return ApiService.query("v2/verifikasi/master/user/akses", { params: credentials })
    },

}