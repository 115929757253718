<template>
  <div class="pl-5 br-5" data-app>

    <div v-if="!loadD">
      <div v-if="list.length > 0">
        <div v-for="(item, index) in list" :key="index" class="border rounded p-3 mb-5">
          <div class="d-flex align-items-center mx-3">
            <div class="symbol symbol-35 symbol-light mr-4">
              <span class="symbol-label">
                <span class="svg-icon svg-icon-md svg-icon-primary">
                  <!--begin::Svg Icon-->
                  <inline-svg src="media/svg/icons/Home/Building.svg"/>
                  <!--end::Svg Icon-->
                </span> 
              </span>
            </div>         
            <span class="text-dark-75 font-size-h5 font-weight-bolder mr-auto">
                {{ item.text }}
            </span>
          </div>
          
          <!--begin::Separator-->
          <div class="separator separator-solid mt-3 mb-4"></div>
          <!--end::Separator-->

          <div v-for="(rinc, indexRin) in item.usaha" :key="indexRin" class="align-items-center mx-sm-7 mx-2">
            <div class="d-flex align-items-center">
              <!--begin::Symbol-->
              <div class="symbol symbol-35 symbol-light mr-4">
                <span class="symbol-label">
                  <span class="svg-icon svg-icon-sm svg-icon-primary">
                    <!--begin::Svg Icon-->
                    <inline-svg src="media/svg/icons/Layout/Layout-polygon.svg"/>
                    <!--end::Svg Icon-->
                  </span> 
                </span>
              </div>
              <!--end::Symbol-->
              <!--begin::Text-->
              <div class="d-flex flex-column flex-grow-1">
                <div class="text-muted font-weight-bold">
                  {{ rinc.komoditas.uraian }} | {{ rinc.tipe.uraian }} {{ rinc.tipeusaha.uraian }}
                </div>
              </div>
              <!--end::Text-->
            </div>
            <!--begin::Separator-->
            <div class="separator separator-border-2 separator-light separator-dashed mb-2"></div>
            <!--end::Separator-->
          </div>
        </div>
      </div>
      <div v-else class="border rounded p-3 mb-5 text-center">
        <span class="text-dark-75 font-size-lg font-weight-bolder">Tidak ada data Akses, silahkan tambah data</span>
      </div>

    </div>
    <div v-else>
      <BulletListLoader></BulletListLoader>
    </div>
  </div>
  <!--end: List Widget 4-->
</template>

<script>
import { BulletListLoader } from 'vue-content-loader'
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import VerifikasiService from "@/core/services/api/verifikasi/master/user.service";
import ErrorService from "@/core/services/error.service";

export default {
  name: "verifikasi-master-data-user-perusahaan",
  props: {
    idser: {
      required: true
    },
    aks: {
      required: true
    }
  },
  components: {
    BulletListLoader
  },  
  data() {
    return {
      dialog: false,
      loadD: true,
      list: [],
      show: true
    }
  },
  methods: {
    getData () {
      VerifikasiService.getUserAkses({idser: this.idser})
        .then((res) => {
            this.loadD = false
            if (!res.data.status) {
                ErrorService.message(res.data)
                this.$emit('error-show', true)
                return;
            }
            this.$emit('error-show', false)
            this.list = res.data.data
        })
        .catch((err) => {
          this.loadD = false
          this.$emit('error-show', true)
          ErrorService.status(err)
        })
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Verif Master Data User" }]);
    this.getData()
  },
};
</script>