<template>
  <div class="pl-5 br-5" data-app>

    <div v-if="dataOri">
        <!--begin::Form Group-->
        <div class="form-group row">
            <label class="col-xl-3 col-lg-3 col-form-label">Provinsi</label>
            <div class="col-lg-9 col-xl-7">
                    <b-form-group
                        id="input-group-user-1" 
                        label-for="input-user-1"
                        class="mb-0">
                          <span id="input-user-1" class="form-control form-control-solid">
                            {{ form.provinsi.uraian }}
                          </span>
                    </b-form-group>
            </div>
        </div>

        <!--begin::Form Group-->
        <div class="form-group row">
            <label class="col-xl-3 col-lg-3 col-form-label">Kabupaten</label>
            <div class="col-lg-9 col-xl-7">
                    <b-form-group
                        id="input-group-user-3" 
                        label-for="input-user-3"
                        class="mb-0">
                          <span id="input-user-3" class="form-control form-control-solid">
                            {{ form.kabupaten.uraian }}
                          </span>
                    </b-form-group>
            </div>
        </div>

        <!--begin::Form Group-->
        <div class="form-group row">
            <label class="col-xl-3 col-lg-3 col-form-label">Komoditas</label>
            <div class="col-lg-9 col-xl-7">
                    <b-form-group
                    id="input-group-user-2" 
                    label-for="input-user-2"
                    class="mb-0">
                      <b-form-checkbox-group
                        id="input-user-2"
                        disabled='disabled'
                        v-model="form.komoditas"
                        size="lg"
                        :options="opsKomoditas"
                        name="flavour-1"
                      ></b-form-checkbox-group>
                    </b-form-group>
            </div>
        </div>
        
    </div>
    <div v-else>
      <BulletListLoader></BulletListLoader>
    </div>
  </div>
  <!--end: List Widget 4-->
</template>

<script>
import { BulletListLoader } from 'vue-content-loader'
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import MasterService from "@/core/services/api/master/master.service";
import VerifikasiService from "@/core/services/api/verifikasi/master/user.service";
import ErrorService from "@/core/services/error.service";

export default {
  name: "verifikasi-master-data-user-akses-kabupaten",
  props: {
    idser: {
      required: true
    },
    aks: {
      required: true
    }
  },
  components: {
    BulletListLoader
  },  
  data() {
    return {
      dataOri: null,  
      form: {
        idser:this.idser,
        provinsi: null,
        kabupaten: null,
        komoditas: []
      },
      opsKomoditas: [],
      show: true
    }
  },
  methods: {
    getTernakKomoditas() {
      MasterService.getTernakKomoditas()
        .then((res) => {
            if (!res.data.status) {
                ErrorService.message(res.data)
                this.opsKomoditas = []
                return;
            }

            this.opsKomoditas = res.data.data
        })
        .catch((err) => {
          this.opsKomoditas = []
          ErrorService.status(err)
        })
    },
    getData () {
      VerifikasiService.getUserAkses({idser: this.idser})
        .then((res) => {
            if (!res.data.status) {
                ErrorService.message(res.data)
                this.$emit('error-show', true)
                return;
            }
            this.$emit('error-show', false)
            this.dataOri = JSON.stringify({ ...res.data.data, idser: this.idser })
            this.form = { ...res.data.data, idser: this.idser }
        })
        .catch((err) => {
          this.$emit('error-show', true)
          ErrorService.status(err)
        })
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Verif Master Data User" }]);
    this.getTernakKomoditas()
    this.getData()
  },
};
</script>